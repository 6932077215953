<template>
  <fw-layout>
    <template #main-sidebar>
      <SidebarManage />
    </template>
    <template #main-content>
      <PanelActivity />
    </template>
  </fw-layout>
</template>

<script>
import PanelActivity from '@/fw-modules/fw-core-vue/ui/components/panels/PanelActivity'
import SidebarManage from '@/components/sidebars/SidebarManage'

export default {
  name: 'ManagePeople',
  components: {
    SidebarManage,
    PanelActivity,
  },

  computed: {
    permissions() {
      return this.$store.getters.userPermissions
    },
  },
}
</script>
