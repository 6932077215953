<template>
  <SidebarBase>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar :to="seeAsPublic ? '/' : '/manage'" icon="home" :exact="true" :label="$t('home')" />
        <ButtonSidebar to="/manage/procedures" :label="$t('procedures')">
          <template #svg>
            <faicon icon="cube" size="lg" class="h-6 w-6 -ml-0.5"></faicon>
          </template>
        </ButtonSidebar>
        <ButtonSidebar
          v-if="isAdminOrManager || permissions.isProcedureManager"
          to="/manage/people"
          icon="people"
          :label="$t('people')"
        />
        <ButtonSidebar v-if="isAdminOrManager" to="/manage/activity" icon="activity" :label="$t('activity')" />
        <ButtonSidebar
          v-if="isAdminOrManager"
          to="/manage/notifications"
          icon="message-sent"
          :label="$t('notifications')"
        />
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  name: 'SidebarManage',
  components: {
    ButtonSidebar,
    SidebarBase,
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    permissions() {
      return this.$store.getters.userPermissions
    },
    isAdminOrManager() {
      return this.permissions.isAdmin || this.permissions.isManager
    },
    language() {
      return this.$store.state.language
    },
    seeAsPublic() {
      return this.$store.getters.getSeeAsPublic
    },
  },
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "en": {
    "home": "Home",
    "procedures": "Procedures",
    "people": "People",
    "activity": "Activity",
    "notifications": "Notifications"
  },
  "pt": {
    "home": "Início",
    "procedures": "Procedimentos",
    "people": "Pessoas",
    "activity": "Atividade",
    "notifications": "Notificações"
  }
}
</i18n>
